import * as THREE from 'three'
import * as dat from 'lil-gui'
import * as CANNON from 'cannon-es'
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js'
import { Raycaster, Vector2 } from 'three'
import { CSS2DRenderer, CSS2DObject } from 'three/addons/renderers/CSS2DRenderer.js'
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils";
import { createMachine, interpret } from 'xstate';

/**
 * Sizes
 */

function getSizes() {
    return {
      width: window.innerWidth, // Pixel
      height: window.innerHeight, // Pixel
    };
  }
  
// Usage
const sizes = getSizes()

/**
 * Canvas
 */
function setupCanvas() {
    return document.querySelector('canvas.webgl')
}
// Usage 
const canvas = setupCanvas()

/**
 * Scene
 */
function setupScene() {
    return new THREE.Scene()
}
// Usage
const scene = setupScene()

/**
 * Camera
 */
// Base camera
function createOrthographicCamera(sizes, cameraZoom) {
    const camera = new THREE.OrthographicCamera(
      -sizes.width / 2, // These Values are units within the THREE.js Env
      sizes.width / 2,
      sizes.height / 2,
      -sizes.height / 2,
      0.1,
      100
    );
    camera.position.set(0, 10, 0);
    camera.lookAt(0, 0, 0);
    camera.zoom = cameraZoom;
    camera.updateProjectionMatrix();
  
    return camera;
  }
// Usage
const cameraZoom = 90; // Set the desired camera zoom value
const camera = createOrthographicCamera(sizes, cameraZoom);

/**
 * Renderer
 */
function createRenderer(canvas, sizes) {
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas
    });

    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    const rendererSize = new THREE.Vector2();
    renderer.getSize(rendererSize);
  
    return {renderer: renderer,
            rendererSize: rendererSize
    }
}
  
// Usage
const renderer = createRenderer(canvas, sizes);

/**
 * Converting from the Canvas Coordinates and the 3D world
 */

function getWindowSize() {
    return new THREE.Vector2(window.innerWidth, window.innerHeight);
}
// Usage
var windowSize = getWindowSize()

function calculateCanvasEdges(renderer, camera, windowSize) {
  
    // const rendererAspect = renderer.rendererSize.x / renderer.rendererSize.y;
    // const windowAspect = windowSize.x / windowSize.y;

    const rendererAspect = windowSize.x / windowSize.y;
    const windowAspect = windowSize.x / windowSize.y;
  
    const widthScale = windowAspect / rendererAspect;
    const heightScale = rendererAspect / windowAspect;
  
    const canvasLeft = camera.position.x + camera.left * (widthScale > 1 ? widthScale : 1) / camera.zoom;
    const canvasRight = camera.position.x + camera.right * (widthScale > 1 ? widthScale : 1) / camera.zoom;
    const canvasTop = camera.position.z + camera.top * (heightScale > 1 ? 1 : heightScale) / camera.zoom;
    const canvasBottom = camera.position.z + camera.bottom * (heightScale > 1 ? 1 : heightScale) / camera.zoom;
  
    return {
      rendererAspect: rendererAspect,
      windowAspect: windowAspect,
      widthScale: widthScale,
      heightScale: heightScale,
      canvasLeft: canvasLeft,
      canvasRight: canvasRight,
      canvasTop: canvasTop,
      canvasBottom: canvasBottom,
    };
} 
// Usage
var canvasEdges = calculateCanvasEdges(renderer, camera, windowSize)

/**
 * Textures
 */

// Box Detail Texture

function createTransparentMeshStandardMaterial(texturePath) {
    const texture = new THREE.TextureLoader().load(texturePath)
    texture.transparent = true
  
    const material = new THREE.MeshStandardMaterial({ map: texture, transparent: true })
    return material
}  
// Usage
const boxDetailTexturePath = 'textures/box_detail_white.png';
const boxDetail = createTransparentMeshStandardMaterial(boxDetailTexturePath)

// Video Textures 
// Video Texture

function createVideoMaterial(src) {
    let copyVideo;
    const video = document.createElement('video');
    video.src = src;
    video.autoplay = true;
    video.loop = true;
    video.muted = true;
    video.load();
  
    video.addEventListener("playing", function() {
      copyVideo = true;
    }, true);
  
    video.addEventListener("ended", function() {
      video.currentTime = 0;
      video.play();
    }, true);
  
    const videoTexture = new THREE.VideoTexture(video);
    videoTexture.wrapS = THREE.RepeatWrapping;
    videoTexture.wrapT = THREE.RepeatWrapping;
  
    const material = new THREE.MeshStandardMaterial({
      map: videoTexture,
      side: THREE.FrontSide,
      toneMapped: false
    });
  
    return {
      video: video,
      copyVideo: () => copyVideo,
      material: material,
      videoTexture: videoTexture
    };
}
// Usage
const textureRepeat = 10;
  
const video1 = createVideoMaterial('video_textures/bg_video.mp4');
video1.material.map.repeat.set(textureRepeat, textureRepeat / (windowSize.x / windowSize.y));
  
const video2 = createVideoMaterial('video_textures/test_video.mp4');
const video3 = createVideoMaterial('video_textures/test_video_2.mp4');
  
const bgMaterial = video1.material;
const CubeMaterial = video2.material;
const CubeMaterial2 = video3.material;
  
video1.video.play();
video2.video.play();
video3.video.play();

/**
 * Physics
 */
function createCannonWorld() {
    const world = new CANNON.World();
    world.broadphase = new CANNON.SAPBroadphase(world);
    world.allowSleep = false;
    world.gravity.set(0, 0, 0);
  
    // Default material
    const defaultMaterial = new CANNON.Material('default');
    const defaultContactMaterial = new CANNON.ContactMaterial(
      defaultMaterial,
      defaultMaterial,
      {
        friction: 0.1,
        restitution: 0.7
      }
    );
    world.defaultContactMaterial = defaultContactMaterial;
  
    return {world: world,
            defaultMaterial: defaultMaterial
  }
}
  
// Usage
const world = createCannonWorld();

/**
 * Floor
 */
function createFloor(world, scene, canvasEdges, material) {
    const floorShape = new CANNON.Plane();
    const floorBody = new CANNON.Body();
    floorBody.mass = 0;
    floorBody.addShape(floorShape);
    floorBody.quaternion.setFromAxisAngle(new CANNON.Vec3(-1, 0, 0), Math.PI * 0.5);
    world.world.addBody(floorBody);
  
    const floor = new THREE.Mesh(
      new THREE.PlaneGeometry(
        canvasEdges.canvasRight - canvasEdges.canvasLeft,
        canvasEdges.canvasTop - canvasEdges.canvasBottom
      ),
      material
    );
  
    floor.receiveShadow = true;
    floor.rotation.x = -Math.PI * 0.5;
    scene.add(floor);
  
    floor.userData.ground = true;
  
    return floor;
  }
  
// Usage
const floor = createFloor(world, scene, canvasEdges, bgMaterial);

/**
 * Utils
 */
const objectsToUpdate = []
const objectsToUpdate2 = []


/**
 * Create Box
 */

const boxStateMachine = {
    id: 'box',
    initial: 'inactive',
    states: {
      inactive: {
        on: {
          TOGGLE: {
            target: 'active',
            actions: ['turnOnPage', 'shrinkBox'],
          },
        },
      },
      active: {
        on: {
          TOGGLE: {
            target: 'inactive',
            actions: ['turnOffPage', 'restoreBox'],
          },
          FLIP: {
            target: 'inactive',
            actions: ['restoreBox'],
          },
        },
      },
    },
  };  
  
var boxCounter = 0

function createBox (width, height, depth, position, material) {
    boxCounter = boxCounter + 1

    // Create box
    const boxGeometry = new THREE.BoxGeometry(1, 1, 1)
    const wireframeMaterial = new THREE.MeshStandardMaterial({
    color: ('#FFFFFF'),
    wireframe: true
    })

    // Three.js mesh
    const mesh1 = new THREE.Mesh(boxGeometry, material)
    mesh1.scale.set(width, height, depth)
    mesh1.castShadow = true
    mesh1.position.copy(position)
    scene.add(mesh1)
    
    mesh1.userData.draggable = true
    mesh1.userData.name = `BOX ${boxCounter}`
    mesh1.userData.material = material
    mesh1.userData.clicked = false

    const mesh2 = new THREE.Mesh(boxGeometry, boxDetail)
    mesh2.scale.set((width + 0.5) , (width + 0.5), (width + 0.5))
    mesh2.castShadow = true
    mesh2.position.copy(position)
    scene.add(mesh2)

    const mesh3 = new THREE.Mesh(boxGeometry, wireframeMaterial)
    mesh3.scale.set((width - 1) , 0.1, (width - 1))
    mesh3.position.set(position.x, 10, position.z)
    scene.add(mesh3)

    // Cannon.js body
    const shape = new CANNON.Box(new CANNON.Vec3(width * 0.5, height * 0.5, depth * 0.5))

    const body = new CANNON.Body({
        mass: 1,
        position: new CANNON.Vec3(0, 3, 0),
        shape: shape,
        material: world.defaultMaterial
    })
    body.position.copy(position)
    world.world.addBody(body)

    const box = {
        mesh1 : mesh1,
        mesh2 : mesh2,
        mesh3 : mesh3,
        body : body
    }

    const boxActions = {
        shrinkBox: (context, event) => {
          event.box.scale.set(0.5, 0.5, 0.5);
        },
        restoreBox: (context, event) => {
          event.box.scale.set(2, 2, 2);
          console.log('box restored')
        },
        turnOnPage: (context, event) => {
          turnOnPage(event.box);
        },
        turnOffPage: (context, event) => {
          turnOffPage();
        }
    };

      
    // Create an interpreter for the state machine
    const boxService = interpret(createMachine(boxStateMachine, { actions: boxActions }));
    boxService.start();

    // Listen to state changes
    boxService.onTransition((state) => {
        console.log(`Box ${mesh1.userData.name} state:`, state.value);
    });

    // Associate the interpreter with the box
    mesh1.stateService = boxService;

    // Save in objects
    objectsToUpdate.push(box)
}

// Usage
createBox(2,2,2,        {
    x: (Math.random() - 0.5) * 3,
    y: 3,
    z: (Math.random() - 0.5) * 3
}, CubeMaterial)

createBox(2,2,2,        {
    x: (Math.random() - 0.5) * 3,
    y: 3,
    z: (Math.random() - 0.5) * 3
}, CubeMaterial2)

/**
 * 3D Assets
 */

function mergeGeometries(meshes) {
  const geometries = meshes.map((mesh) => {
      const geometry = mesh.geometry.clone();
      geometry.applyMatrix4(mesh.matrixWorld);
      return geometry;
  });

  const mergedGeometry = BufferGeometryUtils.mergeBufferGeometries(geometries)
 
  return mergedGeometry;
}

function computeBoundingBox(object) {
    const box = new THREE.Box3().setFromObject(object);
    const size = new THREE.Vector3();
    box.getSize(size);
    return {
        box,
        size,
    };
}

const loader = new GLTFLoader();

loader.load('/3D_assets/OVERLOAD_INDUSTRIES.glb', function (gltf) {
    const meshes = [];
    gltf.scene.traverse(function (node) {
        if (node.isMesh) {
            meshes.push(node);
        }
    });

    // Merge the glTF meshes into a single geometry
    const mergedGeometry = mergeGeometries(meshes);
    const material = new THREE.MeshStandardMaterial();

    // Create a new mesh with the combined geometry and material
    const mergedMesh = new THREE.Mesh(mergedGeometry, material);

    scene.add(mergedMesh);

    // Optionally, you can adjust the scale, rotation, or position of your model
    const scale = new THREE.Vector3(1, 1, 1);
    const rotation = new THREE.Euler(2, 0.7, 3.2);
    const position = new THREE.Vector3(2, 1.5, 2);

    mergedMesh.scale.copy(scale);
    mergedMesh.rotation.copy(rotation);
    mergedMesh.position.copy(position);

    // Calculate the bounding box of the 3D object
    const { box, size } = computeBoundingBox(mergedMesh);

    // Create a dummy object to apply transformations
    const dummy = new THREE.Object3D();
    dummy.scale.copy(scale);
    dummy.rotation.copy(rotation);
    dummy.position.copy(position);

    // Update the bounding box with the transformations
    box.min.applyMatrix4(dummy.matrixWorld);
    box.max.applyMatrix4(dummy.matrixWorld);

    console.log('Bounding box:', box);
    console.log('Bounding box size:', size);

    // Create a CANNON box using the dimensions of the bounding box
    const halfExtents = size.multiplyScalar(.5);
    const shape = new CANNON.Box(new CANNON.Vec3(halfExtents.x, halfExtents.y, halfExtents.z));

    const body = new CANNON.Body({
        mass: 1,
        shape: shape,
        material: world.world.defaultMaterial
    });

    // Apply the same transformations to the CANNON box
    body.position.copy(position);
    body.quaternion.setFromEuler(rotation.x, rotation.y, rotation.z, 'XYZ');
    world.world.addBody(body);

    const boxGeo = {
        mesh1 : mergedMesh,
        body : body
    }

    objectsToUpdate2.push(boxGeo)

}, undefined, function (error) {
    console.error(error);
});

/**
 * Create Walls
 */

const containerPlanes = [];

function createContainer(xmin, xmax, ymin, ymax, zmin, zmax) {
    const planes = [
    { pos: [xmin, 0, 0], quat: [0, Math.PI / 2, 0] },
    { pos: [xmax, 0, 0], quat: [0, -Math.PI / 2, 0] },
    { pos: [0, ymin, 0], quat: [-Math.PI / 2, 0, 0] },
    { pos: [0, ymax, 0], quat: [Math.PI / 2, 0, 0] },
    { pos: [0, 0, zmin], quat: [0, 0, 0] },
    { pos: [0, 0, zmax], quat: [0, Math.PI, 0] },
    ];

    planes.forEach(({ pos, quat }) => {
    const planeShape = new CANNON.Plane();
    const plane = new CANNON.Body({ mass: 0, material: world.defaultMaterial });
    plane.addShape(planeShape);
    plane.quaternion.setFromEuler(...quat);
    plane.position.set(...pos);
    world.world.addBody(plane);
    containerPlanes.push(plane);
    });
}
// Usage
createContainer(canvasEdges.canvasLeft, canvasEdges.canvasRight, 0, 9.5, canvasEdges.canvasBottom, canvasEdges.canvasTop)

/**
 * Lights
 */
function addLights(scene) {
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.2);
    scene.add(ambientLight);
  
    const pointLight = new THREE.PointLight(0xffffff, 0.9);
    pointLight.castShadow = true;
    pointLight.shadow.mapSize.set(3000, 3000);
    pointLight.position.set(-10, 8, -5);
    scene.add(pointLight);
}
// Usage
addLights(scene);

/**
 * Webapge
 */

function turnOnPage(uiBox) {
    switch (uiBox.userData.name) {
      case "BOX 1":
        setPageContent("Header 1", "BOX 1", "/images/box_1/Screenshot 2023-04-07 113549.jpg", "https://www.youtube.com/embed/D_en0lt-W_Y");
        break;
      case "BOX 2":
        setPageContent("Header 2", "BOX 2", "/images/box_1/Screenshot 2023-04-07 113549.jpg", "https://www.youtube.com/embed/urNdG-X50AU");
        break;
      default:
        break;
    }
}
  
function turnOffPage() {
    p.className = 'tooltip hide';
    h.className = 'tooltip hide';
    img.className = 'tooltip hide';
    closeButton.className = 'tooltip hide';
    v.className = 'tooltip hide';
}
  
function setPageContent(headerText, paragraphText, imagePath, videoSrc) {
    p.className = 'tooltip show';
    h.className = 'tooltip show';
    img.className = 'tooltip show';
    closeButton.className = 'tooltip show';
    v.className = 'tooltip show';
    divContainer.position.set(0, 0, 0);
    h.textContent = headerText;
    p.textContent = paragraphText;
    img.setAttribute('src', imagePath);
    img.setAttribute('height', '30');
    img.setAttribute('width', '30');
    v.setAttribute('src', videoSrc);
    v.setAttribute('width', '560');
    v.setAttribute('height', '315');
    v.setAttribute('frameborder', '0');
    v.setAttribute('allow', 'accelerometer');
}
  
const h = document.createElement('h1');
const p = document.createElement('p');
const img = document.createElement('img');
const closeButton = document.createElement('button');
const v = document.createElement('iframe');
p.className = 'tooltip';
h.className = 'tooltip';
img.className = 'tooltip';
v.className = 'tooltip';
closeButton.className = 'tooltip';
closeButton.innerText = 'X';
const pContainer = document.createElement('div');
pContainer.id = 'pContainer';
pContainer.appendChild(h);
pContainer.appendChild(p);
pContainer.appendChild(img);
pContainer.appendChild(closeButton);
pContainer.appendChild(v);
const divContainer = new CSS2DObject(pContainer);
scene.add(divContainer);
  
const labelRenderer = new CSS2DRenderer();
labelRenderer.setSize(window.innerWidth, window.innerHeight);
labelRenderer.domElement.style.position = 'absolute';
labelRenderer.domElement.style.top = "0px";
document.body.appendChild(labelRenderer.domElement);

function adaptLayout() {
    if (window.innerWidth <= 768) {
      // Adapt the content for mobile devices
      pContainer.style.display = 'flex';
      pContainer.style.flexDirection = 'column';
      pContainer.style.padding = '20px';
      pContainer.style.overflowY = 'auto';
      pContainer.style.maxHeight = 'calc(100vh - 40px)';
    } else {
      // Adapt the content for desktop/laptop devices
      pContainer.style.display = 'grid';
      pContainer.style.gridTemplateRows = 'auto 1fr';
      pContainer.style.gridTemplateColumns = '1fr 1fr';
      pContainer.style.padding = '20px';
      pContainer.style.overflowY = 'hidden';
      h.style.gridColumn = '1 / 3';
      p.style.gridColumn = '1';
      p.style.gridRow = '2';
      p.style.overflowY = 'auto';
      img.style.gridColumn = '2';
      img.style.gridRow = '2';
      img.style.overflowY = 'auto';
      v.style.gridColumn = '2';
      v.style.gridRow = '3';
      v.style.overflowY = 'auto';
    }
}
  
// Call adaptLayout() initially
adaptLayout();  

/**
 * raycaster
 */
const raycaster = new Raycaster();
const clickMouse = new Vector2();

/**
 * Listeners
 */

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight
    windowSize = getWindowSize()

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    labelRenderer.setSize(sizes.width, sizes.height)
    renderer.renderer.setSize(sizes.width, sizes.height)
    renderer.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    // Scene
    camera.left = -sizes.width / 2;
    camera.right = sizes.width / 2;
    camera.top = sizes.height / 2;
    camera.bottom = -sizes.height / 2;
    camera.updateProjectionMatrix();
    
    // Update window Size
    canvasEdges = calculateCanvasEdges(renderer, camera, windowSize)
    
    // Update Floor
    floor.geometry.dispose();
    floor.geometry = new THREE.PlaneGeometry(canvasEdges.canvasRight-canvasEdges.canvasLeft, canvasEdges.canvasTop-canvasEdges.canvasBottom);

    // Update Planes
    for (const plane of containerPlanes) {
        world.world.removeBody(plane);
    }
    containerPlanes.length = 0;

    createContainer(canvasEdges.canvasLeft, canvasEdges.canvasRight, 0, 9.5, canvasEdges.canvasBottom, canvasEdges.canvasTop)

    // Update Bg Texture
    bgMaterial.map.repeat.set(textureRepeat, textureRepeat/( windowSize.x / windowSize.y));

    // Update pContainer layout
    adaptLayout();
})

//
let touchFlag = false;
let activeBox = null;

function handleEvent(event) {
    if (event.type === 'click' && touchFlag) {
        touchFlag = false;
        return;
    }

    let clientX, clientY;

    if (event instanceof TouchEvent) {
        touchFlag = true;
        clientX = event.touches[0].pageX;
        clientY = event.touches[0].pageY;
    } else {
        clientX = event.clientX;
        clientY = event.clientY;
    }

    clickMouse.x = (clientX / window.innerWidth) * 2 - 1;
    clickMouse.y = -(clientY / window.innerHeight) * 2 + 1;

    raycaster.setFromCamera(clickMouse, camera);
    let sceneObjects = objectsToUpdate.map((item) => item.mesh1);
    const found = raycaster.intersectObjects(sceneObjects);

    if (found.length > 0) {
        const clickedBox = found[0].object;
        const clickedBoxState = clickedBox.stateService.state.value;

        if (clickedBoxState === 'inactive') {
            clickedBox.stateService.send({ type: 'TOGGLE', box: clickedBox });
            if (activeBox) {
                activeBox.stateService.send({ type: 'TOGGLE', box: activeBox });
            }
            activeBox = clickedBox;
            turnOnPage(clickedBox)

            sceneObjects.forEach((e) => {
                if (e !== found[0].object && e.stateService.state.value === 'active') {
                    e.stateService.send({ type: 'FLIP', box: e });
                    
                }
            });

        } else if (clickedBoxState === 'active') {
            clickedBox.stateService.send({ type: 'TOGGLE', box: clickedBox });
            activeBox = null;
        }
    }
}

window.addEventListener('touchstart', handleEvent);
window.addEventListener('click', handleEvent);

closeButton.addEventListener('click', () => {
    if (activeBox) {
      activeBox.stateService.send({ type: 'TOGGLE', box: activeBox });
      turnOffPage();
      activeBox = null;
    }
  });

/**
 * Animate
 */
const clock = new THREE.Clock()
let oldElapsedTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - oldElapsedTime
    oldElapsedTime = elapsedTime

    // Update physics
    world.world.step(1 / 60, deltaTime, 3)
    
    for(const object of objectsToUpdate)
    {
        object.mesh1.position.copy(object.body.position)
        object.mesh2.position.copy(object.body.position)
        object.mesh3.position.copy(object.body.position)
        object.mesh1.quaternion.copy(object.body.quaternion)
        object.mesh2.quaternion.copy(object.body.quaternion)
    }

    for(const object of objectsToUpdate2)
    {
        object.mesh1.position.copy(object.body.position)
        object.mesh1.quaternion.copy(object.body.quaternion)
    }

    // Update Video Textures
    video1.videoTexture.needsUpdate = true
    video2.videoTexture.needsUpdate = true
    video3.videoTexture.needsUpdate = true

    // Render
    labelRenderer.render(scene, camera)

    renderer.renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()